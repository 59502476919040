import React, { useEffect } from "react";
import { navigate } from "gatsby"

const AcademyPage = () => {
    useEffect(() => {
        navigate("/academy/growth-fundamentals-course/");
    },[])
  return (
    <></>
  )
}

export default AcademyPage
